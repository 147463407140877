import { clearPictureVideoIds } from '@utils/sessionStorage'

export const deprecatedClearPicture = () => {
  // eslint-disable-next-line no-console
  console.warn(
    // eslint-disable-next-line quotes
    `This method will be removed in the next major version,
      if you want to clear the picture state without deleting privacy policies state
      please use namespace\'s method and pass false as parameter: vmmv.reset(false)`
  )
  clearPictureVideoIds()
}
