import * as t from 'io-ts'

const PriceOrderCodec = t.union([t.literal('fullPrice'), t.literal('newPrice')])

export const PriceLineOrderCodec = t.array(PriceOrderCodec, 'PriceLineOrder')

export const CurrencySideCodec = t.union([t.literal('LEFT'), t.literal('RIGHT')])

export type PriceLineOrder = t.TypeOf<typeof PriceLineOrderCodec>

export type PriceOrder = t.TypeOf<typeof PriceOrderCodec>

export type CurrencySide = t.TypeOf<typeof CurrencySideCodec>

export const parsePriceOrder = (codec: t.Type<PriceLineOrder>) =>
  new t.Type<PriceLineOrder>(
    codec.name,
    (u: unknown): u is PriceLineOrder => u === undefined || codec.is(u),
    (u, ctx) => {
      if (u === undefined) {
        // set dafault value
        return t.success(['fullPrice', 'newPrice'])
      } else {
        // check for duplicate
        const values = u as PriceLineOrder

        if (values.length > 2) {
          return t.failure(u, ctx, `Invalid ${codec.name}`)
        } else {
          const uniqueValues = new Set(values)

          if (uniqueValues.size !== values.length) {
            // duplicates values
            return t.failure(u, ctx, `${codec.name} has a duplicate value`)
          }
        }
      }

      return codec.validate(u, ctx)
    },
    a => (a === undefined ? a : codec.encode(a))
  )

export const parseCurrencySide = (codec: t.Type<CurrencySide>) =>
  new t.Type<CurrencySide>(
    codec.name,
    (u: unknown): u is CurrencySide => u === undefined || codec.is(u),
    (u, ctx) => {
      if (u === undefined) {
        // set dafault value
        return t.success('LEFT')
      }

      return codec.validate(u, ctx)
    },
    a => (a === undefined ? a : codec.encode(a))
  )
