import styled from 'styled-components'

export const VMMVOverlay = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
`

export const VMMVModal = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  width: 100%;
  max-width: 644px;
  max-height: 800px;

  // all ipads and mobile devices
  @media only screen and (min-device-width: 712px) and (max-device-width: 1024px) and (orientation: portrait),
    (max-width: 767.98px) {
    max-width: 100%;
    max-height: 100%;
  }
`
