import * as t from 'io-ts'

export enum VtoPage {
  LiveTryOn = 'live-try-on',
  PictureMode = 'picture-mode',
  VideoMode = 'video-mode',
}

export const VtoPageDecoder = t.union(
  [t.literal('live-try-on'), t.literal('picture-mode'), t.literal('video-mode')],
  'VtoPage'
)

export type Experience = t.TypeOf<typeof VtoPageDecoder>
