import { Config } from '@customTypes/config'
import { CurrencySide } from '@customTypes/price'

export const setDefaultValues = (config: Config) => {
  // VMC-425 for backward compatibility if the integrator is not using setConfig function, for the moment we will not return an error but instead it will fix the wrong inputs
  // TODO: this will deprecated on the future if we force the integrator to use only setConfig
  let configCopy = { ...config }
  if (
    !configCopy.priceLineOrder ||
    typeof configCopy.priceLineOrder !== 'object' ||
    !Array.isArray(configCopy.priceLineOrder)
  ) {
    // set default value
    configCopy.priceLineOrder = ['fullPrice', 'newPrice']
  }

  // check duplication and the length of the priceLineOrder
  const uniqueOrders = new Set(configCopy.priceLineOrder)
  if (
    uniqueOrders.size !== configCopy.priceLineOrder.length ||
    configCopy.priceLineOrder.length > 2
  ) {
    // set default
    configCopy.priceLineOrder = ['fullPrice', 'newPrice']
  }

  // check if has a valid values
  const [first, second] = configCopy.priceLineOrder
  configCopy.priceLineOrder = []

  if (first === 'fullPrice' || first === 'newPrice') {
    configCopy.priceLineOrder.push(first)
  }

  if (second === 'fullPrice' || second === 'newPrice') {
    configCopy.priceLineOrder.push(second)
  }

  if (configCopy.priceLineOrder.length === 0) {
    // set default
    configCopy.priceLineOrder = ['fullPrice', 'newPrice']
  }

  if (
    !configCopy.currencySide ||
    (configCopy.currencySide.toUpperCase() !== 'LEFT' &&
      configCopy.currencySide.toUpperCase() !== 'RIGHT')
  ) {
    configCopy.currencySide = 'LEFT'
  }

  configCopy.currencySide = configCopy.currencySide.toUpperCase() as CurrencySide

  return configCopy
}
