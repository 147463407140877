import { Config } from './types/config'
import { createRoot, Root } from 'react-dom/client'
import pkgVersion from './version'
import { VMWidgetQRCode } from './qrCodeWidget'

import { isBrowserSupported, isUPCSupported, isValidConfig } from '@utils/helpers'
import { reset } from '@utils/reset'
import { warmUp } from '@utils/warmUp'
import { getVMP } from '@utils/getVMP'

import { deprecatedClearPicture } from '@utils/deprecatedClearPicture'
import { VMConfiguratorsWidget } from './widgets/ConfiguratorsWidget'
import { VM_MODAL_ID } from '@components/VMModal'
import { setDefaultValues } from '@utils/setDefaultValues'

interface IVMWidgetApp {
  config: Config | undefined
  init: () => void
  setConfig: (config: Config) => Promise<void>
  unmount: () => Promise<void>
  reset: () => Promise<void> // for removal in VMMV 6.0
}

export const VMMV_ROOT_CONTAINER = 'vmmv-root'

// export const VMWidgetRoot = {
//   root: null as Root | null,
//   get getRoot() {
//     return this.root
//   },
//   set setRoot(root: Root) {
//     this.root = root
//   },
// }

class VMWidgetApp implements IVMWidgetApp {
  public config: Config | undefined
  private root: Root | null | undefined
  private isModal: boolean | undefined

  // for removal in VMMV version 6.0
  constructor(config?: Config) {
    if (config) {
      // eslint-disable-next-line no-console
      console.warn(
        'Please use setConfig method to set the config, new VMWidgetApp(config) will be deprecated in the future'
      )

      this.config = setDefaultValues(config)

      const onClose = this.config.onClose
      if (!!onClose) {
        this.config.onClose = () => {
          this.unmountVMMVRootContainer()
          onClose()
        }
      }
    }
  }

  async init() {
    const appConfig = this.config

    if (!appConfig) {
      // eslint-disable-next-line no-console
      console.error('Please use setConfig method to set the config')

      throw new Error('Config is not defined')
    }

    const { VmWidgetUi } = await import('./VmWidgetUi')
    const { AppLoader } = await import('@components/Loader')

    if (!appConfig.selector) {
      const vmmvRootEl = document.createElement('div')
      vmmvRootEl.id = VMMV_ROOT_CONTAINER
      vmmvRootEl.style.position = 'fixed'
      vmmvRootEl.style.inset = '0'
      vmmvRootEl.style.zIndex = '999'

      document.body.append(vmmvRootEl)
      this.root = createRoot(vmmvRootEl)
      // VMWidgetRoot.root = this.root

      const { VMModal } = await import('@components/VMModal')

      this.root.render(
        <VMModal>
          {isLoaded =>
            isLoaded && (
              <>
                <AppLoader container={'#' + VM_MODAL_ID} isDisabled={false} />
                <VmWidgetUi config={appConfig} />
              </>
            )
          }
        </VMModal>
      )

      this.isModal = true
    } else {
      const container = document.querySelector(appConfig.selector)
      this.root = container ? createRoot(container) : null

      if (!this.root) {
        throw new Error(
          `You requested Virtual mirror to render inside the element with the selector ${appConfig.selector}, but there is no such element in the document. Check the "selector" parameter in Virtual mirror initialization or your DOM.`
        )
      }

      this.root.render(
        <>
          <AppLoader container={appConfig.selector} isDisabled={false} />
          <VmWidgetUi config={appConfig} />
        </>
      )

      this.isModal = false
    }
  }

  async setConfig(config: Config) {
    if (this.config) {
      throw new Error('Config is already defined')
    }

    return new Promise<void>((resolve, reject) => {
      isValidConfig(config)
        .then((decoded: Config) => {
          this.config = decoded

          const onClose = this.config.onClose

          if (!!onClose) {
            this.config.onClose = () => {
              this.unmountVMMVRootContainer()
              onClose()
            }
          }
          resolve()
        })
        .catch(error => reject({ error }))
    })
  }

  async unmount() {
    if (this.root) {
      try {
        const { unmount } = await import('@utils/unmount')
        await unmount()
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e)
      }

      this.root.unmount()

      if (this.isModal) {
        // remove the vmmv-root div
        const rootContainer = document.getElementById(VMMV_ROOT_CONTAINER)
        rootContainer?.remove()
      }
    }
  }

  private unmountVMMVRootContainer() {
    if (this.isModal) {
      this.unmount()
    }
  }

  // to be removed with VMC-421
  async reset() {
    if (this.root) {
      // eslint-disable-next-line no-console
      console.warn(
        // eslint-disable-next-line quotes
        `This method will be removed in the next major release,
        please use namespace\' method instead: vmmv.reset()`
      )
      try {
        reset()
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e)
      }
    }
  }

  static new(config: Config) {
    return new VMWidgetApp(config)
  }

  static version() {
    return pkgVersion
  }
}

export {
  VMWidgetApp,
  VMWidgetQRCode,
  VMConfiguratorsWidget,
  deprecatedClearPicture as clearPictureVideoIds,
  isUPCSupported,
  isBrowserSupported,
  isValidConfig,
  reset,
  warmUp,
  getVMP,
}
