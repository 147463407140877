import { Resolution, UpcAvailability } from '@customTypes/upcAvailability'
import { Config, ConfigCodec } from '@customTypes/config'
import { Product, ProductDecoder } from '@customTypes/product'
import { Errors } from 'io-ts'

export const isUPCSupported = async (
  upcs: string[],
  licenseKey: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  glassesEnv: any = 'PROD'
) => {
  const upcList = upcs.filter(x => !!x)

  const upcAvailability: UpcAvailability = {}

  // use dynamic import
  const { VirtualMirrorCatalogue } = await import('@luxottica/virtual-mirror')

  const catalogueUpcs = await VirtualMirrorCatalogue.build({
    key: licenseKey,
    catalogueEnvironment: glassesEnv,
  }).isUpcSupportedImproved(...upcList)

  catalogueUpcs.forEach(upcCatalog => {
    const getResolution = (resolution: { '256': boolean; '512': boolean }) => {
      return [
        resolution[256] ? 'PX_256' : undefined,
        resolution[512] ? 'PX_512' : undefined,
      ].filter(x => !!x) as Resolution[]
    }

    upcAvailability[upcCatalog.getUpc()] = {
      available: upcCatalog.isAvailable(),
      resolutions: getResolution(upcCatalog.available),
      transitions: upcCatalog.isTransition(),
      transitionsResolutions: getResolution(upcCatalog.transitions),
      lods: upcCatalog.lods,
    }
  })

  return upcAvailability
}

export const isBrowserSupported = async () => {
  const { VirtualMirror } = await import('@luxottica/virtual-mirror')

  return await VirtualMirror.isBrowserSupported()
}

interface ConfigError {
  [key: string]: unknown
}

const getErrorData = async (typeName: string, left: Errors, specialType?: string[]) => {
  const t = await import('io-ts')

  let errorData: ConfigError = {}

  const getCurrentErrObj = (keys: string[]): ConfigError => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let currentObj: any = errorData

    keys.forEach(k => {
      if (!(k in currentObj)) {
        currentObj[k] = {}
      }

      currentObj = currentObj[k]
    })

    return currentObj
  }

  left.forEach(e => {
    const ctx = e.context.filter(
      i =>
        i.type.name !== typeName &&
        !i.type.name.startsWith('Intersect') &&
        i.type instanceof t.LiteralType === false
    )

    let keyList: string[] = []
    let currError: ConfigError = errorData

    ctx.forEach((c, i) => {
      const key = c.key
      const actual = c.actual
      const type = c.type.name
      const isLast = ctx.length === i + 1

      let message = `Expect type ${type}`

      // for special validation if any
      if (
        type === 'string' ||
        (specialType && specialType.length > 0 && specialType.includes(type))
      ) {
        const v = c.type.validate(actual, e.context)

        if (v._tag === 'Left') {
          const { left } = v
          if (left.length > 0 && left[0].message) message = left[0].message
        }
      }

      const isParent =
        c.type instanceof t.IntersectionType ||
        c.type instanceof t.ArrayType ||
        c.type instanceof t.InterfaceType ||
        c.type instanceof t.PartialType ||
        c.type instanceof t.Type

      if (isParent) {
        if (isParent) keyList.push(key)

        currError = getCurrentErrObj(keyList)

        if (isLast) {
          // no child
          currError['actual'] = actual
          currError['error'] = message
        }
      } else {
        currError[key] = { actual, error: message }
      }
    })
  })

  return errorData
}

export const isValidConfig = (value: Config) => {
  return new Promise<Config>(async (resolve, reject) => {
    // need to have a better way to handle this additional condition if Brand is required
    if (!value.isTakeScreenshotEnabled) {
      // brand is no longer required because take screenshot is disable
      // but we need to make sure that brand is available since it was required in Config
      if (value.products && !!value.products.length) {
        value.products.forEach(p => {
          p.brand = {
            name: '',
            logoUrl: '',
          }
        })
      }
    }

    let result = ConfigCodec.decode(value)

    if (result._tag == 'Right') {
      resolve(result.right as Config)
    } else {
      const errorData = await getErrorData('Config', result.left, ['PriceLineOrder'])

      reject({ config: errorData })
    }
  })
}

export const isValidProduct = (value: Product) => {
  return new Promise<Product>(async (resolve, reject) => {
    let result = ProductDecoder.decode(value)

    if (result._tag == 'Right') {
      resolve(result.right as Product)
    } else {
      const errorData = await getErrorData('Product', result.left)

      reject({ product: errorData })
    }
  })
}
