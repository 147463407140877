import * as t from 'io-ts'

export const AnalyticsCodec = t.partial(
  {
    adobeSessionId: t.string,
    pagePlatform: t.string,
    pageBrand: t.string,
    storeId: t.string,
    sourcePosition: t.string,
    pageEnvironment: t.string,
    storeRegion: t.string,
    storeCompany: t.string,
    storeGlobalId: t.string,
    source: t.string,
    style: t.string,
  },
  'Analytics'
)

export type Analytics = t.TypeOf<typeof AnalyticsCodec>
