import * as t from 'io-ts'

export const LensItemDecoder = t.intersection(
  [
    t.type(
      {
        name: t.string,
        colorName: t.string,
        lensIdentifier: t.string,
      },
      'IntersectLensItem01'
    ),
    t.partial({ swatchImage: t.string }, 'IntersectLensItem02'),
  ],
  'LensItem'
)

export type LensItem = t.TypeOf<typeof LensItemDecoder>
