import * as t from 'io-ts'
import * as E from 'fp-ts/Either'

export type Maybe<T> = T | null | undefined

export const truncateText = (length: number) =>
  new t.Type<string, string, unknown>(
    'string',
    t.string.is,
    (u, c) =>
      E.either.chain(t.string.validate(u, c), s => {
        return s.length === 0
          ? t.failure(u, c, 'the value should not be empty string')
          : t.success(`${s.length > length ? `${s.substring(0, length)}...` : s}`)
      }),
    String
  )
