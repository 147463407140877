import { Product } from '@customTypes/product'
import { isValidProduct } from './helpers'

export const setProduct = async (product: Product) => {
  try {
    await isValidProduct(product)
    const { store } = await import('@store/index')
    const { setProduct } = await import('@store/actions/configurator')

    store.dispatch(setProduct(product))
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Invalid product data', e)
  }
}
