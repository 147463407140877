import * as t from 'io-ts'

const ActivePanelNameDecoder = t.union([t.literal('lens'), t.literal('color'), t.literal('frame')])

export const TransitionFeatureDecoder = t.partial(
  {
    enabled: t.boolean,
    activePanel: ActivePanelNameDecoder,
  },
  'TransitionFeature'
)

export type TransitionFeature = t.TypeOf<typeof TransitionFeatureDecoder>

export type ActivePanelName = t.TypeOf<typeof ActivePanelNameDecoder>
