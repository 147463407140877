import * as t from 'io-ts'

const glassesEnvDecoder = t.union([
  t.literal('PROD'),
  t.literal('PROD_LEGACY'),
  t.literal('TEST'),
  t.literal('TEST_LEGACY'),
  t.literal('QA_GLTF_PROD'),
  t.literal('QA_GLTF_TEST'),
  t.literal('QA_B3D_PROD'),
  t.literal('QA_B3D_TEST'),
  t.literal('MOCK'),
  t.literal('DISTRIBUTOR_VALIDATION_STAGING'),
  t.literal('DISTRIBUTOR_APPROVATION_STAGING'),
  t.literal('DISTRIBUTOR_VALIDATION_PRODUCTION'),
  t.literal('DISTRIBUTOR_APPROVATION_PRODUCTION'),
])

// const environmentDecoder = t.union([
//   t.literal('STAGING'),
//   t.literal('ACCEPTANCE'),
//   t.literal('PRODUCTION'),
// ])

export const VMInitParamsDecoder = t.intersection(
  [
    t.type(
      {
        source: t.string,
        key: t.string,
        channel: t.string,
        brand: t.string,
      },
      'IntersectVMInitParams01'
    ),
    // Optional
    t.partial(
      {
        // environment: environmentDecoder,
        glassesEnv: glassesEnvDecoder,
      },
      'IntersectVMInitParams02'
    ),
  ],
  'VMInitParams'
)

export type VMInitParams = t.TypeOf<typeof VMInitParamsDecoder>
