const safeEnv = (key: string) => {
  const value = process.env[key]
  if (value === undefined) throw new Error(`Missing env variable ${key}`)
  return value
}

const boolean = (value: string | undefined) => {
  return Boolean(value && value.toLowerCase && value.toLowerCase() === 'true')
}

export const defaultConfig = {
  baseUrl: safeEnv('REACT_APP_BASE_URL'),
  translationPath: safeEnv('REACT_APP_TRANSLATION_PATH'),
  vmApiUrl: safeEnv('REACT_APP_VM_API_URL'),
  vmUrl: safeEnv('REACT_APP_VM_URL'),
  debugAnalytics: boolean(safeEnv('REACT_APP_ANALYTICS_DEBUG')),
  qrCodeWidgetEnabled: boolean(safeEnv('REACT_APP_QR_CODE_WIDGET_ENABLED')),
  vmEnv: safeEnv('REACT_APP_VM_ENV').toUpperCase(),
  vtoApiUrl: safeEnv('REACT_APP_VTO_API_URL'),
  vmColorApiUrl: safeEnv('REACT_APP_COLORS_URL'),
}

export type AppConfig = typeof defaultConfig

export default defaultConfig
