import { isEmpty } from 'lodash'

const PICTURE_VIDEO_IDS = 'vmmv-picture-video-ids'

const getParsedPictureVideoIds = () => {
  const currentData = localStorage.getItem(PICTURE_VIDEO_IDS) || ''
  const parsedPictureVideoIds = currentData ? JSON.parse(currentData) : {}
  return parsedPictureVideoIds
}

export const getPictureId = () => {
  const parsedPictureVideoIds = getParsedPictureVideoIds()
  const pictureId =
    !isEmpty(parsedPictureVideoIds) &&
    parsedPictureVideoIds.pictureId &&
    parsedPictureVideoIds.pictureId
  return pictureId
}

export const setPictureId = (pictureID: string) => {
  const parsedPictureVideoIds = getParsedPictureVideoIds()
  ;(parsedPictureVideoIds.pictureId = pictureID),
    localStorage.setItem(PICTURE_VIDEO_IDS, JSON.stringify(parsedPictureVideoIds))
}

export const setVideoId = (videoID: string) => {
  const parsedPictureVideoIds = getParsedPictureVideoIds()
  parsedPictureVideoIds.videoId = videoID
  localStorage.setItem(PICTURE_VIDEO_IDS, JSON.stringify(parsedPictureVideoIds))
}

export const getVideoId = () => {
  const parsedPictureVideoIds = getParsedPictureVideoIds()
  const videoId =
    !isEmpty(parsedPictureVideoIds) &&
    parsedPictureVideoIds.videoId &&
    parsedPictureVideoIds.videoId
  return videoId
}

export const clearPictureId = () => {
  const parsedPictureVideoIds = getParsedPictureVideoIds()
  if (parsedPictureVideoIds.pictureId) delete parsedPictureVideoIds.pictureId
  // parsedPictureVideoIds.pictureId = {}
  localStorage.setItem(PICTURE_VIDEO_IDS, JSON.stringify(parsedPictureVideoIds))
}

export const clearVideoId = () => {
  const parsedPictureVideoIds = getParsedPictureVideoIds()
  if (parsedPictureVideoIds.videoId) delete parsedPictureVideoIds.videoId
  // parsedPictureVideoIds.videoId = {}
  localStorage.setItem(PICTURE_VIDEO_IDS, JSON.stringify(parsedPictureVideoIds))
}

export const clearPictureVideoIds = () => localStorage.removeItem(PICTURE_VIDEO_IDS)
