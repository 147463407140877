import { useEffect, useState } from 'react'

import { VMMVModal, VMMVOverlay } from './style'

interface VMModalProps {
  children: (isLoaded: boolean) => React.ReactNode
}

export const VM_MODAL_ID = 'vmmv-modal-container'

export const VMModal: React.FC<VMModalProps> = ({ children }) => {
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => setIsLoaded(true), [])

  return (
    <VMMVOverlay className="vmmv-modal-overlay">
      <VMMVModal id={VM_MODAL_ID} className="vmmv-modal-container">
        {children(isLoaded)}
      </VMMVModal>
    </VMMVOverlay>
  )
}
