import { createRoot, Root } from 'react-dom/client'
import { Config } from '@customTypes/config'
import pkgVersion from '../../version'
import { setLens, setLensesList } from '@utils/setLens'
import { LensItem } from '@customTypes/lens'
import { Product } from '@customTypes/product'
import { setProduct } from '@utils/setProduct'

interface IConfiguratorsWidget {
  config?: Config
  init: () => void
  setLens: (id: string) => Promise<void>
  setLensList: (payload: { lensList: LensItem[] }) => Promise<void>
  setProduct: (product: Product) => Promise<void>
}

class VMConfiguratorsWidget implements IConfiguratorsWidget {
  public config: Config
  private root: Root | null = null
  constructor(config: Config) {
    if (config !== null && config !== undefined && typeof config === 'object') {
      // set default values and remove other parameters that are applicable for Configurator widget since we use VMWidget Config
      // this will ensure config is correct
      // TODO: remove this code once we implement parameter validation and resolve issue regarding useConfig()
      config.fromStore = true
      delete config.prices
      delete config.analytics
      delete config.transitionFeature
      delete config.wishlistedUpcs
      delete config.addToCartText
      delete config.alternativeDecisionPath
      delete config.isQrCodeEnabled
      delete config.onAddPrescriptionLenses
      delete config.onAlternativeDecisionPath
      delete config.onAddToBag
      delete config.onClose
      config.products = config.products.map(p => {
        delete p.isTransition
        delete p.transitionLens
        delete p.rxAvailable
        return p
      })
    }

    this.config = config

    if (this.config.selector) {
      const container = document.querySelector(this.config.selector)
      this.root = container ? createRoot(container) : null
    }
  }

  async init() {
    if (!this.config) {
      throw new Error('Config is not defined')
    }

    if (!this.root) {
      throw new Error(
        `You requested Virtual Mirror Configurator widget to render inside the element with the selector ${this.config.selector}, but there is no such element in the document. Check the "selector" parameter in Virtual mirror initialization or your DOM.`
      )
    }

    const { Ui } = await import('./UI')
    const { AppLoader } = await import('@components/Loader')

    this.root.render(
      <>
        <AppLoader container={this.config.selector} isDisabled={false} />
        <Ui config={this.config} />
      </>
    )
  }

  async setLens(id: string) {
    if (this.root) {
      await setLens(id)
    }
  }

  async setLensList(payload: { lensList: LensItem[] }) {
    if (this.root) {
      await setLensesList(payload)
    }
  }

  async setProduct(product: Product) {
    if (this.root) {
      await setProduct(product)
    }
  }

  async unmount() {
    if (this.root) {
      try {
        const { unmount } = await import('@utils/unmount')
        await unmount()
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e)
      }

      this.root.unmount()
    }
  }

  static new(config: Config) {
    return new VMConfiguratorsWidget(config)
  }

  static version() {
    return pkgVersion
  }
}

export { VMConfiguratorsWidget }
